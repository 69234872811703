import React, { useState, useEffect } from "react";
import { Button, Typography, Box, Paper, TextField, IconButton } from "@mui/material";
import { Lock, LockOpen } from "@mui/icons-material";
import { usePrintJobs } from "../../../clientUtils/PrintJobsContext";
import AuthService from "../../../clientUtils/auth";
import { useNavigate } from "react-router-dom";

const PrintingSection = ({ fetchImageUrl, token, foundUser }) => {
  let navigate = useNavigate();

  const [purchasePrice, setPurchasePrice] = useState("");
  const [formattedPurchasePrice, setFormattedPurchasePrice] = useState("$0.00");
  const [purchaseLocation, setPurchaseLocation] = useState("SET PURCHASE LOCATION");
  const [salesTaxPercentage, setSalesTaxPercentage] = useState("7.5");
  const [taxAmount, setTaxAmount] = useState("");
  const [numberOfNFTs, setNumberOfNFTs] = useState(0);
  const [pricePerNFT, setPricePerNFT] = useState(5.00);
  const [isPricePerNFTLocked, setIsPricePerNFTLocked] = useState(false);
  const { addPrintJob } = usePrintJobs();

  // Load the saved location from localStorage when the component mounts
  useEffect(() => {
    const savedLocation = localStorage.getItem("purchaseLocation");
    if (savedLocation) {
      setPurchaseLocation(savedLocation);
    }
  }, []);

  // Save the location to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("purchaseLocation", purchaseLocation);
  }, [purchaseLocation]);

  const handleSavePurchase = async (
    nftId,
    series,
    individual,
    verificationCode,
    price,
    taxPercentage,
    location,
    customerID,
  ) => {
    const numeral = price.replace(/\$/g, "");
    const originalPrice = parseFloat(numeral);
    const taxAmount = (originalPrice * parseFloat(taxPercentage)) / 100;
    const finalPrice = originalPrice + taxAmount;

    const formattedFinalPrice = { $numberDecimal: finalPrice.toFixed(2).toString() };
    const formattedOriginalPrice = { $numberDecimal: originalPrice.toFixed(2).toString() };
    const formattedTaxAmount = { $numberDecimal: taxAmount.toFixed(2).toString() };

    const requestBody = {
      customerID: customerID,
      price: formattedFinalPrice,
      originalPrice: formattedOriginalPrice,
      tax: formattedTaxAmount,
      location: location,
      nft: nftId,
      printTimes: 1, // Initialize printTimes to 1
    };

    console.log(requestBody);

    try {
      const response = await fetch("/api/purchase/new", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to add purchase");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePriceChange = (e) => {
    const input = e.target.value.replace(/[^\d.]/g, ""); // Allow decimal numbers
    setPurchasePrice(input); // Update the raw price
    // Format the price as currency
    const formattedInput = input
      ? `$${parseFloat(input).toFixed(2)}`
      : "";
    setFormattedPurchasePrice(formattedInput);
  
    // Update number of NFTs based on the new total price
    if (!isPricePerNFTLocked) {
      const newNumberOfNFTs = input ? Math.floor(parseFloat(input) / pricePerNFT) : 0;
      setNumberOfNFTs(newNumberOfNFTs);
    }
  };

  const handleSalesTaxChange = (e) => {
    const input = e.target.value.replace(/[^\d.]/g, ""); // Remove non-numeric characters except dot
    setSalesTaxPercentage(input);
  };

  const handleNumberOfNFTsChange = (e) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const newNumberOfNFTs = input ? parseInt(input, 10) : 0;
    setNumberOfNFTs(newNumberOfNFTs);
  
    // Update total price based on the new number of NFTs
    const newTotalPrice = newNumberOfNFTs * pricePerNFT;
    setPurchasePrice(newTotalPrice.toString());
    setFormattedPurchasePrice(`$${newTotalPrice.toFixed(2)}`);
  };

  const handlePricePerNFTChange = (e) => {
    const input = e.target.value.replace(/[^0-9.]/g, ""); // Allow only numbers and decimal points
    setPricePerNFT(input); // Update the state with the raw input value
  
    if (!isPricePerNFTLocked) {
      const newPricePerNFT = input ? parseFloat(input) : 0;
      // Update total price based on the new price per NFT
      const newTotalPrice = numberOfNFTs * newPricePerNFT;
      setPurchasePrice(newTotalPrice.toString());
      setFormattedPurchasePrice(`$${newTotalPrice.toFixed(2)}`);
    }
  };

  const togglePricePerNFTLock = () => {
    setIsPricePerNFTLocked(!isPricePerNFTLocked);
  };

  const calculateFinalPrice = (price, taxPercentage) => {
    const priceNumber = parseFloat(price.replace(/\$/g, ""));
    const taxNumber = parseFloat(taxPercentage);
    const taxAmountValue = (priceNumber * taxNumber) / 100;
    setTaxAmount(taxAmountValue.toFixed(2)); // Set taxAmount state as a string formatted to two decimal places
    const finalPrice = priceNumber + taxAmountValue;
    return `$${finalPrice.toFixed(2)}`;
  };

  const calculateTotalPriceWithTax = (price, taxPercentage) => {
    const priceNumber = parseFloat(price.replace(/\$/g, ""));
    const taxNumber = parseFloat(taxPercentage);
    const taxAmountValue = (priceNumber * taxNumber) / 100;
    const finalPrice = priceNumber + taxAmountValue;
    return `$${finalPrice.toFixed(2)}`;
  };

  const verifyCode = async (responseNftCode) => {
    try {
      const url = `/api/admin/verify/${responseNftCode}`;
      console.log(url);
      const response = await fetch(url, {
        method: "PUT",
      });
      const data = await response.json();
      console.log(data);
    } catch (err) {
      console.log("could not verify", err);
    }
  };

  const handlePrint = async (shouldVerify = false) => {
    if (!foundUser) {
      console.error("No user found for printing.");
      return;
    }

    const customerID = foundUser._id;
    const purchasePriceNumber = parseFloat(formattedPurchasePrice.replace(/\$/g, ""));
    console.log(`purchasePriceNumber: ${purchasePriceNumber}`);
    console.log(`numberOfNFTs: ${numberOfNFTs}`);

    let nftDetails = [];
    let nftSections = [];

    for (let i = 0; i < numberOfNFTs; i++) {
      const { nftId, responseImageUrl, series, individual, responseNftCode } = await fetchImageUrl();
      nftDetails.push({ nftId, responseImageUrl, series, individual, responseNftCode });

      // Call handleSavePurchase for each NFT
      await handleSavePurchase(
        nftId,
        series,
        individual,
        responseNftCode,
        i === 0 ? formattedPurchasePrice : "$0.00", // Only send the price for the first NFT
        salesTaxPercentage,
        purchaseLocation,
        customerID,
      );

      if (shouldVerify) {
        await verifyCode(responseNftCode);
      }

      const qrCodeToEncode = `mynftsolutions.com/claim/${responseNftCode}`;
      const qrCodeImage = `<img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(qrCodeToEncode)}" alt="QR Code">`;
      const productImage = `<img src="${responseImageUrl}" style="max-width:100%;height:auto;" alt="Product">`;

      //<p><strong>Price:</strong> ${i === 0 ? formattedPurchasePrice : "$0.00"}</p>
      nftSections.push(`
        <p>Your NFT can be claimed here:</p>
        <div class="container">
          ${qrCodeImage}
          <div class="text">
            <p>OR by visiting</p>
            <h4>MYNFTSOLUTIONS.COM/CLAIM</h4>
            <p>with the code:</p>
            <h4>${responseNftCode}</h4>
            
          </div>
          ${productImage}
        </div>
      `);
    }

    const finalPriceWithTax = calculateFinalPrice(formattedPurchasePrice, salesTaxPercentage);

    const printJob = {
      date: new Date(),
      purchasePrice: finalPriceWithTax,
      purchaseLocation,
      nfts: nftDetails,
    };

    addPrintJob(printJob);

    const date = new Date();
    
    const content = `
    <html>
      <head>
        <title>Print Document</title>
        <style>
          body {
            margin: 0;
            padding: 20px;
            font-family: Arial, sans-serif;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
          }
          .text {
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: center;
          }
        </style>
      </head>
      <body>
        <h1>NFT Solutions</h1>
        ${nftSections.join('')}
        <div class="text">
          <h2>Total Purchase Details</h2>
          <p><strong>Total Price:</strong> ${finalPriceWithTax}</p>
          <p><strong>Location:</strong> ${purchaseLocation}</p>
          <p><strong>Date:</strong> ${date}</p>
        </div>
      </body>
    </html>`;

    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.onload = function () {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };

  const handlePrintAndVerify = async () => {
    await handlePrint(true);
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Printing Section
      </Typography>
      {foundUser && (
        <Typography variant="body1" gutterBottom>
          Customer: {foundUser.firstName} {foundUser.lastName}
        </Typography>
      )}
      <Box mt={2} mb={2}>
        <TextField
          label="Number of NFTs"
          variant="outlined"
          fullWidth
          margin="normal"
          value={numberOfNFTs}
          onChange={handleNumberOfNFTsChange}
        />
        <Box display="flex" alignItems="center">
          <TextField
            label="Price per NFT"
            variant="outlined"
            fullWidth
            margin="normal"
            value={pricePerNFT}
            onChange={(e) => handlePricePerNFTChange(e)}
            disabled={isPricePerNFTLocked}
          />
          <IconButton onClick={() => setIsPricePerNFTLocked(!isPricePerNFTLocked)}>
            {isPricePerNFTLocked ? <Lock /> : <LockOpen />}
          </IconButton>
        </Box>
        <TextField
          label="Sales Tax %"
          variant="outlined"
          fullWidth
          margin="normal"
          value={salesTaxPercentage}
          onChange={(e) => handleSalesTaxChange(e)}
        />
        <TextField
          label="Purchase Location"
          variant="outlined"
          fullWidth
          margin="normal"
          value={purchaseLocation}
          onChange={(e) => setPurchaseLocation(e.target.value)}
        />
      </Box>
      <Typography variant="subtitle1" gutterBottom>
        Total Price (excluding Sales Tax): {formattedPurchasePrice}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Total Price (inc. Sales Tax): {calculateTotalPriceWithTax(formattedPurchasePrice, salesTaxPercentage)}
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <Button variant="contained" color="primary" onClick={() => handlePrint(false)} disabled={!foundUser}>
          Print
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handlePrintAndVerify}
          disabled={!foundUser}
        >
          Print & Verify
        </Button>
        <Typography variant="subtitle1" gutterBottom>
          We recommend that you verify any purchases at the time of redemption
          for better security.
        </Typography>
      </Box>
    </Paper>
  );
};

export default PrintingSection;